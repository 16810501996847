/*********************
* Fonts
*********************/
@function str-replace($string, $search: ' ', $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}
$default_path: '../fonts' !default;
@mixin fontface($family, $weight, $style, $type) {
    $dir-name: to-lower-case(str-replace('#{$family}', ' ', '-'));
    $file-name: to-lower-case(str-replace('#{$family}-latin-#{$weight}-#{$style}', ' ', '-'));
    @font-face {
        font-family: '#{$family}';
        font-weight: $weight;
        font-style: $style;
        src: local('#{$family} #{$type}'), local( str-replace( '#{$family}-#{$type}')),
        url('#{$default_path}/#{$dir-name}/files/#{$file-name}.woff2') format('woff2'), /* Super Modern Browsers */
        url('#{$default_path}/#{$dir-name}/files/#{$file-name}.woff') format('woff'); /* Modern Browsers */
    }
}
// DM Sans
/* These fonts are licensed under the Open Font License.
You can use them in your products & projects – print or digital, commercial or otherwise.
This isn't legal advice, please consider consulting a lawyer and see the full license for all details. */
@include fontface('DM Sans', 400, normal, 'Regular');
@include fontface('DM Sans', 500, normal, 'Medium');
@include fontface('DM Sans', 700, normal, 'Bold');
// Poppins
/* These fonts are licensed under the Open Font License.
You can use them in your products & projects – print or digital, commercial or otherwise.
This isn't legal advice, please consider consulting a lawyer and see the full license for all details. */
@include fontface('Poppins', 400, normal, 'Regular');
@include fontface('Poppins', 500, normal, 'Medium');
@include fontface('Poppins', 700, normal, 'Bold');

/*********************
* Theme
*********************/
@import "cronos-extension/variables.scss";
@import "cronos-extension/maxmaxmax/variables.scss";
@import "../../node_modules/bulma/sass/utilities/initial-variables.sass";
@import "../../node_modules/bulma/sass/utilities/functions.sass";
@import "../../node_modules/bulma/sass/utilities/derived-variables.sass";

$orange-invert   : findColorInvert($orange);
$yellow-invert   : findColorInvert($yellow);
$green-invert    : findColorInvert($green);
$turquoise-invert: findColorInvert($turquoise);
$cyan-invert     : findColorInvert($cyan);
$blue-invert     : findColorInvert($blue);
$purple-invert   : findColorInvert($purple);
$red-invert      : findColorInvert($red);

$primary-invert  : findColorInvert($primary);
$primary-light   : findLightColor($primary);
$primary-dark    : findDarkColor($primary);
$info-invert     : findColorInvert($info);
$info-light      : findLightColor($info);
$info-dark       : findDarkColor($info);
$success-invert  : findColorInvert($success);
$success-light   : findLightColor($success);
$success-dark    : findDarkColor($success);
$warning-invert  : findColorInvert($warning);
$warning-light   : findLightColor($warning);
$warning-dark    : findDarkColor($warning);
$danger-invert   : findColorInvert($danger);
$danger-light    : findLightColor($danger);
$danger-dark     : findDarkColor($danger);
$light-invert    : findColorInvert($light);
$dark-invert     : findColorInvert($dark);

$link-invert     : findColorInvert($link);
$link-light      : findLightColor($link);
$link-dark       : findDarkColor($link);

$colors: mergeColorMaps(("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert, $primary-light, $primary-dark), "link": ($link, $link-invert, $link-light, $link-dark), "info": ($info, $info-invert, $info-light, $info-dark), "success": ($success, $success-invert, $success-light, $success-dark), "warning": ($warning, $warning-invert, $warning-light, $warning-dark), "danger": ($danger, $danger-invert, $danger-light, $danger-dark)), $custom-colors);
$shades: mergeColorMaps(("black-bis": $black-bis, "black-ter": $black-ter, "grey-darker": $grey-darker, "grey-dark": $grey-dark, "grey": $grey, "grey-light": $grey-light, "grey-lighter": $grey-lighter, "white-ter": $white-ter, "white-bis": $white-bis), $custom-shades);
$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

/*********************
* Bulma
*********************/
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";

@import "cronos-extension/shuffle-extension.scss";

// Custom Styles
@import "_cookie-optin.scss";
@import "_overlay.scss";

html {
    font-size: 16px;
    scroll-behavior: smooth;
    scroll-padding-top: 104px; // Scroll Offset
}

.subtitle {
    line-height: 1.5;
}

.is-rotate-45 {
    transform: rotate(45deg);
}
.is-rotate-90 {
    transform: rotate(90deg);
}
.is-rotate-180 {
    transform: rotate(180deg);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-container {
    padding: 3rem 1.5rem;
}
@include mobile {
    .form-container {
        padding: 0;
    }
}