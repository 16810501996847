.optin {
    --fontHead: #{$title-family};
    --fontBody: #{$body-family};
    --colorPrime: #{$primary};
    --colorLink: #{$link};
    --colorSuccess: #{$success};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: none;
    width: 95%;
    max-width: 500px;
    max-height: 75vh;
    padding: 1rem;
    border-radius: .5rem;
    border: 1px solid var(--colorPrime);
    background-color: white;
    box-shadow: 0 0 0 200rem rgba(black, .5);
    &__container {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 70vh;
        overflow-y: scroll;
    }
    &__image {
        position: absolute;
        top: -1rem;
        right: 0;
        transform: translate(50%, -50%);
        width: 300px;
        height: auto;
    }
    &__link {
        color: var(--colorLink);
    }
    &__heading {
        display: block;
        margin: 0 0 .25em 0;
        font: 300 1.5rem/1.3em var(--fontHead);
        color: var(--colorPrime);
    }
    &__description {
        display: block;
        margin: 0 auto 1rem;
        font: 300 .875rem/1.5em var(--fontBody);
        color: var(--black);
    }
    &__list {
        display: block;
        font: 300 .875rem/1.5em var(--fontBody);
        color: var(--black);
        li {
            font: 300 .875rem/1.5em var(--fontBody);
        }
    }
    &__button {
        // display: inline-block;
        // margin: 0 0 1rem .25rem;
        // padding: .5rem 1rem;
        // font: 400 1rem/1em var(--fontBody);
        // text-decoration: none;
        // white-space: nowrap;
        // color: white;
        // outline: none;
    }
    &__settings {
        display: none;
        width: 100%;
        margin: 0 auto;
    }
    &__option {
        display: block;
        width: 100%;
        margin: .5rem 0;
        padding: .5rem;
        background-color: rgba(black, .1);
        border-radius: .5rem;
        &__heading {
            display: block;
            margin: 1rem 0 0 0;
            font-family: var(--fontBody)!important;
            font-weight: 700;
            font-size: 1rem!important;
            line-height: 1.3em;
            color: var(--black);
        }
        &__description {
            display: block;
            font: 300 .75rem/1.5em var(--fontBody);
            color: var(--black);
        }
    }
    &__toggle-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    &__switch {
        position: relative;
        display: inline-block;
        min-width: 2.5rem;
        min-height: 1.5rem;
        margin: 0 1rem 0 0;
        &__input {
            width: 0;
            height: 0;
            opacity: 0;
            &:checked {
                & + .optin__switch__toggle {
                    background-color: var(--colorSuccess);
                    &::before {
                        transform: translateX(1.25rem);
                    }
                }
            }
            &:focus {
                & + .optin__switch__toggle {
                    box-shadow: 0 0 1px var(--colorSuccess);
                }
            }
        }
        &__toggle {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1.5rem;
            cursor: pointer;
            background-color: grey;
            border-radius: 1.5rem;
            transition: .4s;
            &::before {
                position: absolute;
                content: '';
                height: 1rem;
                width: 1rem;
                left: .125rem;
                bottom: .25rem;
                border-radius: 1rem;
                background-color: white;
                transition: .4s
            }
        }
    }
    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: .5rem;
        border-top: 1px solid var(--colorPrime);
        &__item {
            display: inline-block;
            font: 300 .875rem/1em var(--fontBody);
            color: var(--black);
            margin: 0 1rem;
            text-decoration: underline;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
@media all and (max-width: 640px) {
    .optin {
        padding: 1rem;
        &__container {
            max-height: 50vh;
        }
        &__image {
            display: none;
        }
    }
}