.overlay {
    position: fixed!important;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    &__close {
        position: fixed;
        top: -1rem;
        right: -1rem;
        z-index: 1;
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid white;
        background-color: black;
        transition: all ease .5s;
        &:hover {
            cursor: pointer;
            transform: rotate(90deg);
        }
    }
    &--loading {
        position: fixed!important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgba(black, .25);
        backdrop-filter: blur(.5rem);
        &__container {
            margin: auto auto;
            padding: 1rem;
            background-color: white;
            overflow-y: scroll;
            overflow-x: hidden;
            &--loader {
                @extend .overlay--loading__container!optional;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: min-content;
                text-align: center;
                background-color: rgba(white, 0);
                overflow: hidden;
            }
        }
        &:after {
            content: '';
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(black, .5);
            width: 15rem;
            height: 15rem;
            border-radius: 1.5rem;
        }
    }
}
#loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-loader {
    color: white;
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    div {
        transform-origin: 15px 15px;
        animation: loader 1.2s linear infinite;
        &:after {
            content: " ";
            display: block;
            position: absolute;
            top: 0px;
            left: 14px;
            width: 2px;
            height: 7px;
            border-radius: 5px;
            background-color: white;
        }
        &:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -1.1s;
        }
        &:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }
        &:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }
        &:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }
        &:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }
        &:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }
        &:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }
        &:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }
        &:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }
        &:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }
        &:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }
        &:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }
    }
}
@keyframes loader {
    0% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
    }
}
@media screen and (max-width: 640px) {
    .overlay {
        max-width: calc(100% - 2rem);
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        &__close {
            top: 0;
            right: 0;
        }
    }
}